html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* background: url(/img/mario-bg.png) no-repeat; */
  background-size: 100%;
  background-position: bottom;
  background-color: #95e8f3;
  min-height: 100%;
}

form {
  padding: 20px;
  margin-top: 60px;
}

form button,
form h5 {
  margin: 20px 0;
}

input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #ec407a;
  box-shadow: none;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label {
  color: #ec407a !important;
}

.left-button {
  left: 23px;
  right: auto;
}

.slim {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.lang {
  /* float: left;
   padding-left: 4px;
   padding-right: 4px; */
  background-color: gold;
  border: none;
  color: black;
  padding: 2px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.fab-container {
	position: fixed;
	bottom: 30px;
	right: 15px;
	z-index: 999;
	cursor: pointer;
}

.fab-icon-holder {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background: #016fb9;

	box-shadow: 0 6px 20px rgba(0,0,0,0.2);
}

.fab-icon-holder:hover{
	opacity: 0.8;
}

.fab-icon-holder i {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;
	font-size: 25px;
	color: #ffffff;
}

.fab {
	width: 60px;
	height: 60px;
	background: #d23f31;
}

.fab-options {
	list-style-type: none;
	margin: 0;

	position: absolute;
	bottom: 70px;
	right: 0;

	opacity: 0;

	transition: all 0.3s ease;
	transform: scale(0);
	transform-origin: 85% bottom;
}

.fab:hover + .fab-options, .fab-options:hover {
	opacity: 1;
	transform: scale(1);
}

.fab-options li {
	display: flex;
	justify-content: flex-end;
	padding: 5px;
	padding-top: 20px;
}

.fab-label {
	padding: 2px 5px;
	align-self: center;
	user-select: none;
	white-space: nowrap;
	border-radius: 3px;
	font-size: 16px;
	background: #666666;
	color: #ffffff;
	box-shadow: 0 6px 20px rgba(0,0,0,0.2);
	margin-right: 10px;
}
